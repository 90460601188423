.sku-details h3 {
  display: inline;
  margin: 0;
}

.sku-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.sku-details code {
  font-size: small;
}

.sku-details p {
  margin-top: 0.5em;
}

.sku-details {
  margin: 1em 0;
}