/* ph3 pv1 ma1 white dib bn */

.button {
  padding: 6px 15px;
  margin: 3px;
  color: white;
  display: inline-block;

  border: 3px solid rgba(255, 255, 255, 0);
  border-radius: 3px;
}

.button:hover:enabled {
  border: 3px solid rgba(255, 255, 255, 1);
}

.button:disabled {
  background-color: grey;
}